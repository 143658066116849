import { Environment } from './environment.type';

export const environment: Environment = {
  name: 'production',
  production: true,
  api: {
    host: 'api.app.c-rayon.com',
    port: 443,
    ssl: true,
  },
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import firebase from 'firebase/app';
import 'firebase/auth';

import { ViewerRoutePrams } from '@/models';
import { SessionService } from '@/services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private sessionService: SessionService,
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | UrlTree {

    const tenantId: string = childRoute.params.tenantId;
    const liveId: string = childRoute.params.liveId ?? childRoute.queryParams.liveId;

    if (!liveId) {
      return this.router.parseUrl('/');
    }

    return this.sessionService.initializeApp().pipe(
      map((firebaseUser: firebase.User | null) => {
        return this.userGuard({
          state,
          firebaseUser,
          liveId,
          tenantId,
        });
      })
    );
  }

  userGuard({
      state,
      tenantId,
      liveId,
      firebaseUser
    }: {
      state: RouterStateSnapshot;
      tenantId: string;
      liveId: string;
      firebaseUser: firebase.User | null;
    }
    ) {
    if (state.url.includes('home')) {
      if (firebaseUser) {
        return true;
      }
      return this.router.parseUrl(`/${tenantId}/sign-in?liveId=${liveId}`);
    }

    if (state.url.includes('sign-in')) {
      if (!firebaseUser) {
        return true;
      }
      return this.router.parseUrl(`/${tenantId}}/home/${liveId}`);
    }
    return true;

  }
}

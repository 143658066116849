import { Action, ActionReducer, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

import { LiveComment } from '@/models';

export const addComment = createAction('[LiveComment] Add live comment', props<{ comment: LiveComment }>());
export const unshiftComments = createAction(
  '[LiveComment] unshift live comments',
  props<{ comments: LiveComment[] }>()
);
export const resetComments = createAction('[LiveComment] Reset live comments');

export interface State {
  commentIds: string[];
  commentById: { [id: string]: LiveComment };
}

export const initialState: State = {
  commentIds: [],
  commentById: {},
};


export const liveCommentReducer: ActionReducer<State, Action> = createReducer(
  initialState,
  on(addComment, (state, { comment }) => {
    const ids = [...new Set([...state.commentIds, comment.id])];
    const byId: { [id: string]: LiveComment } = {}; //state.commentById;
    byId[comment.id] = comment;
    return { ...state, commentIds: ids, commentById: { ...state.commentById, ...byId } };
  }),
  on(unshiftComments, (state, { comments }) => {
    const _commentIds = [...comments.map(c => c.id), ...state.commentIds];
    const commentIds = Array.from(new Set(_commentIds));

    const byId: { [id: string]: LiveComment } = {}; //state.commentById;
    comments.forEach(c => (byId[c.id] = c));
    const commentById: { [id: string]: LiveComment } = { ...state.commentById, ...byId };

    return { ...state, commentIds, commentById };
  }),
  on(resetComments, () => {
    return { ...initialState };
  })
);

export const selectFeature = createFeatureSelector<State>('liveComment');
export const getLiveComments = createSelector(selectFeature, state =>
  state.commentIds.map(id => state.commentById[id])
);
export const getLastComment = createSelector(selectFeature, state =>
  state.commentIds.length > 0 ? state.commentById[state.commentIds[state.commentIds.length - 1]] : null
);

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FirebaseConfig } from '@environments/environment.type';
import { TenantConfig } from '@/models';

// NOTE: 他にconfigが必要になったら命名を変更
@Injectable({
  providedIn: 'root'
})
export class FirebaseConfigApi {
  private url: string = '/public/group/setting';

  constructor(
    private http: HttpClient,
  ) {}

  fetchFirebaseConfig(tenantId: string): Observable<FirebaseConfig> {
    return this.http.get<TenantConfig>(this.url)
      .pipe(
        map((config: TenantConfig) => JSON.parse(config.firebaseConfig as string) as FirebaseConfig)
      );
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReferrerGuard implements CanActivateChild {
  constructor() {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const allowDomains: string[] = [
      `${childRoute.params.tenantId}.admin.app-staging.c-rayon.com`,
      `${childRoute.params.tenantId}.admin.app.c-rayon.com`
    ];
    if (
      !(allowDomains.some(domain => document.referrer.includes(domain)))
      && !childRoute.queryParams.preview
    ) {
      return false;
    }
    return true;
  }
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Deserializer, DeserializerOptions } from 'jsonapi-serializer';

import { environment } from '@environment';
import { ConfigService } from '@/services/config/config.service';

@Injectable({ providedIn: 'root' })
export class ApiResponseInterceptor implements HttpInterceptor {
  private tenantId: string;

  get apiHost(): string {
    return `${this.tenantId}.${environment.api.host}${environment.api.port !== 443 ? `:${environment.api.port}` : '' }`;
  }

  constructor(
    { tenantId }: ConfigService
  ) {
    this.tenantId = tenantId;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      mergeMap(event => {
        if (
          event instanceof HttpResponse &&
          ((this.isInternalApi(req.url) && !req.headers.has('x-meta-no-change-resource')) ||
            req.headers.has('x-meta-use-json-api'))
        ) {
          const keepOriginalKeyName = req.headers.has('x-meta-respect-original-key-name');

          return this.deserialize(event.body, keepOriginalKeyName).pipe(map(body => event.clone({ body })));
        } else {
          return of(event);
        }
      })
    );
  }

  private deserialize(obj: any, keepOriginalKeyName: boolean): Observable<any> {
    if (!obj || !obj.data) {
      return from(new Promise((resolve, reject) => resolve(null)));
    }
    const option: DeserializerOptions = {};
    if (keepOriginalKeyName) {
      option.keyForAttribute = name => name;
    } else {
      option.keyForAttribute = 'camelCase';
    }
    option.typeAsAttribute = true;
    return from(new Deserializer(option).deserialize(obj));
  }

  private isInternalApi(url: string): boolean {
    // NOTE: cannot contain hostname in url excluding hostname
    return url.includes(url);
  }
}

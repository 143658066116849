import { Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ApiRequestInterceptor } from './api-request-interceptor';
import { ApiResponseInterceptor } from './api-response-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders: Provider = [
  { provide: HTTP_INTERCEPTORS, useClass: ApiRequestInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ApiResponseInterceptor, multi: true },
];

import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { httpInterceptorProviders } from '@/interceptors/index';
import { reducers } from '@/store';
import { SessionService } from '@/services/session/session.service';


export function jwtOptionsFactory(injector: Injector) {
  // const env: EnvironmentService = injector.get(EnvironmentService);
  return {
    tokenGetter: () => injector.get<SessionService>(SessionService).getIdToken(),
    // whitelistedDomains: [`${env.apiHost}}:${env.api.port}`],
    // blacklistedRoutes: [/^public\//],
  };
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Injector],
      },
    }),
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers)
  ],
  providers: [
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

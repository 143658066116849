import { ActionReducerMap } from '@ngrx/store';

import * as fromLiveComment from './live-comment.store';

export interface AppState {
  liveComment: fromLiveComment.State;
}

export const reducers: ActionReducerMap<AppState> = {
  liveComment: fromLiveComment.liveCommentReducer,
  // liveComment: fromLiveComment.reducer,
};

// export { metaReducers } from './meta';

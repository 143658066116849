import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { InitializeFirebaseConfigGuard } from '@/guards/initialize-firebase-config/initialize-firebase-config.guard';
import { ReferrerGuard } from '@/guards/referrer/referrer.guard';
import { SessionGuard } from '@/guards/session/session.guard';
import { ConfigServiceModule } from '@/services/config/config.module'
import { SessionServiceModule } from '@/services/session/session.module';

const tenantRoutes: Routes = [{
  path: ':tenantId',
  canActivate: [InitializeFirebaseConfigGuard],
  canActivateChild: [ReferrerGuard],
  children: [
    {
      path: 'sign-in',
      loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule)
    },
    {
      path: 'home/:liveId',
      loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
    }
  ]
}];

const routes: Routes = [
  {
    path: '',
    children: tenantRoutes
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    SessionServiceModule,
    HttpClientModule,
    ConfigServiceModule,
  ],
  exports: [RouterModule],
  providers: [
    InitializeFirebaseConfigGuard,
    ReferrerGuard,
    SessionGuard
  ]
})
export class AppRoutingModule { }

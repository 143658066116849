import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import firebase from 'firebase/app';

import { FirebaseConfig } from '@environments/environment.type';
import { FirebaseConfigApi } from '@/apis/firebase-config/firebase-config.api';

// NOTE: 他にconfigが必要になったら命名を変更
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private _tenantId: string = '';
  set tenantId(id: string) {
    this._tenantId = id;
  }
  get tenantId(): string {
    return this._tenantId;
  }

  constructor(
    private api: FirebaseConfigApi,
  ) {
    this.api.fetchFirebaseConfig;
  }

  initializeApp(tenantId: string): Observable<FirebaseConfig | null> {

    if (firebase.apps.length > 0) {
      return of(null);
    }
    return this.fetchFirebaseConfig(tenantId).pipe(
      tap((config: FirebaseConfig) => {
        firebase.initializeApp(config);
      }, (err) => {
        console.error(err)
      })
    );

  }

  private fetchFirebaseConfig(tenantId: string): Observable<FirebaseConfig>  {
    return this.api.fetchFirebaseConfig(tenantId);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ViewerRoutePrams } from '@/models/route-prams';
import { ConfigService } from '@/services/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class InitializeFirebaseConfigGuard implements CanActivate {
  constructor(
    private router: Router,
    private configService: ConfigService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | UrlTree | boolean {
    const { tenantId } = route.params as ViewerRoutePrams;

    if (!tenantId) {
      return this.router.parseUrl('');
    }

    this.configService.tenantId = tenantId;
    return this.configService.initializeApp(tenantId)
      .pipe(map(() => {
        return true
      }))
  }
}

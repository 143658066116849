import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '@environment';
import { ConfigService } from '@/services/config/config.service';

@Injectable({ providedIn: 'root' })
export class ApiRequestInterceptor implements HttpInterceptor {
  private tenantId: string;

  get apiHost(): string {
    return `${this.tenantId}.${environment.api.host}${environment.api.port !== 443 ? `:${environment.api.port}` : '' }`;
  }
  get requestUrl(): string {
    const url = `http${environment.api.ssl ? 's' : ''}://${this.apiHost}/api`;
    return url;
  }

  constructor({ tenantId }: ConfigService) {
    this.tenantId = tenantId;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let requestUrl;
    let headers: HttpHeaders = req.headers;
    let body = req.body;

    if (this.isInternalApiReq(req.url)) {
      requestUrl = `${this.requestUrl}${req.url}`;

      if (['POST', 'PUT', 'PATCH'].includes(req.method)) {
        headers = headers.set('Content-Type', 'application/json');
        body = this.normalizeKeys(body);
      }
    } else {
      requestUrl = req.url;
    }

    const newReq = req.clone({ headers, body, url: requestUrl });

    return next.handle(newReq);
  }

  private isInternalApiReq(url: string): boolean {
    // NOTE: scheme が指定されている場合は外部リクエストとして処理する
    return !/(http(s)?:\/\/+)/.test(url) && !url.includes(this.apiHost);
  }

  private toSnakeCase(key: string) {
    const snakedKey = key.replace(/([A-Z]+[A-Z])|([A-Z])/g, str => '_' + str.toLowerCase());
    return snakedKey;
  }
  private normalizeKeys(_body: { [key: string]: string | object }) {
    const body: { [key: string]: string | object } = {};
    Object.keys(_body).forEach(_key => {
      const key: string = this.toSnakeCase(_key);
      if (_body[_key] && typeof _body[_key] === 'object') {
        body[key] = this.normalizeKeys(_body[_key] as {[key: string]: string | object });
      } else {
        body[key] = _body[_key];
      }
    });
    return body;
  }
}

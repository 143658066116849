import { NgModule } from '@angular/core';

import { SessionService } from './session.service';

@NgModule({
  providers: [
    SessionService
  ]
})
export class SessionServiceModule { }

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import firebase from 'firebase/app';
import 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  firebaseUser$: BehaviorSubject<firebase.User | {}>;

  constructor() {
    this.firebaseUser$ = new BehaviorSubject<{}>({});
  }

  initializeApp(): Observable<firebase.User | null> {
    return new Observable((observer) => {
        firebase.auth().onAuthStateChanged(
          (user: firebase.User | null) => {
            if (user) {
              this.firebaseUser$.next(user);
            }
            observer.next(user);
          },
          (err) => observer.error(err),
          () => observer.complete()
        );
    })
  }

  getIdToken(): Promise<string> | void {
    const firebaseUser = this.firebaseUser$.value;
    if (!Object.keys(firebaseUser).length) {
      return;
    }
    return (firebaseUser as firebase.User).getIdToken();
  }

  signIn(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }
}
